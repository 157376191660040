import React, { useMemo } from "react";
import { BasicBarChart } from "pages/ApexCharts/BarCharts/BasicBarChart";
import { useQuery } from "@tanstack/react-query";
import { getTotalCustomerByCustomerLocaleV1ShopifyShopSystemShopIdSystemAnalyticsOrderTotalOrderByCustomerLocalePostOptions } from "api/client/@tanstack/react-query.gen";

type TopCountriesProps = {
  system_shop_id?: string;
  start_date?: string;
  end_date?: string;
};

const TopCountries = ({
  system_shop_id,
  start_date,
  end_date,
}: TopCountriesProps) => {
  console.log(system_shop_id, start_date, end_date);
  const { data: topCountries } = useQuery({
    ...getTotalCustomerByCustomerLocaleV1ShopifyShopSystemShopIdSystemAnalyticsOrderTotalOrderByCustomerLocalePostOptions(
      {
        path: { system_shop_id: system_shop_id ?? "" },
        body: {
          start_date,
          end_date,
        },
      }
    ),
    enabled: !!system_shop_id,
  });

  const categories = useMemo(() => {
    // Compute categories based on data
    return topCountries?.data?.map((item: any) => item?.customer_locale);
  }, [topCountries]);

  const series = useMemo(() => {
    // Compute series based on data
    return topCountries?.data?.map((item) => item?.count_order);
  }, [topCountries]);

  return (
    <React.Fragment>
      <div className="order-14 col-span-12 lg:col-span-6 2xl:order-1 card 2xl:col-span-3">
        <div className="card-body">
          <h6 className="mb-3 text-15">Top Countries</h6>
          <BasicBarChart
            chartId="basicBar"
            data={series}
            categories={categories}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default TopCountries;
