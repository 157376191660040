import React from "react";
import {
  Cog,
  Coins,
  Kanban,
  ListFilter,
  ShoppingBag,
  Users,
} from "lucide-react";

import { ShopifyAnalyticTotalPriceAggregate } from "api/http";
import { ShopifyAnalyticAverageOrderValueAggregate } from "api/client";
import CountUp from "react-countup";
import { convertToUsd } from "helpers/convert_money";

export type WidgetsProps = {
  total?: {
    customer?: {
      madePurchase?: number;
      total: number;
    };
    revenue?: ShopifyAnalyticTotalPriceAggregate;
    averageOrder?: ShopifyAnalyticAverageOrderValueAggregate;
    bounceRate?: number;
  };
};

const Widgets = (props: WidgetsProps) => {
  return (
    <React.Fragment>
      <div className="order-1 md:col-span-6 lg:col-span-3 col-span-12 2xl:order-1 bg-green-100 dark:bg-green-500/20 card 2xl:col-span-2 group-data-[skin=bordered]:border-green-500/20 relative overflow-hidden">
        <div className="card-body">
          <Kanban className="absolute top-0 size-32 stroke-1 text-green-200/50 dark:text-green-500/20 ltr:-right-10 rtl:-left-10"></Kanban>
          <div className="flex items-center justify-center size-12 bg-green-500 rounded-md text-15 text-green-50">
            <Users />
          </div>
          <h5 className="mt-5 mb-2">
            <CountUp
              end={props?.total?.customer?.total ?? 0}
              className="counter-value"
            />
          </h5>
          <p className="text-slate-500 dark:text-slate-200">Total Customer</p>
        </div>
      </div>
      <div className="order-1 md:col-span-6 lg:col-span-3 col-span-12 2xl:order-1 bg-yellow-100 dark:bg-yellow-400-200/20 card 2xl:col-span-2 group-data-[skin=bordered]:border-yellow-500/20 relative overflow-hidden">
        <div className="card-body">
          <Kanban className="absolute top-0 size-32 stroke-1 text-yellow-200/50 dark:text-yellow-500/20 ltr:-right-10 rtl:-left-10"></Kanban>
          <div className="flex items-center justify-center size-12 bg-yellow-600 rounded-md text-15 text-yellow-50">
            <ShoppingBag />
          </div>
          <h5 className="mt-5 mb-2">
            <CountUp
              end={props?.total?.customer?.madePurchase ?? 0}
              className="counter-value"
            />
          </h5>
          <p className="text-slate-500 dark:text-slate-200">
            Total Customer Made Purchase
          </p>
        </div>
      </div>
      <div className="order-2 md:col-span-6 lg:col-span-3 col-span-12 2xl:order-1 bg-orange-100 dark:bg-orange-500/20 card 2xl:col-span-2 group-data-[skin=bordered]:border-orange-500/20 relative overflow-hidden">
        <div className="card-body">
          <ListFilter className="absolute top-0 size-32 stroke-1 text-orange-200/50 dark:text-orange-500/20 ltr:-right-10 rtl:-left-10"></ListFilter>
          <div className="flex items-center justify-center size-12 bg-orange-500 rounded-md text-15 text-orange-50">
            <Cog />
          </div>
          <h5 className="mt-5 mb-2">
            <CountUp
              end={convertToUsd(props?.total?.revenue?.total_price_sum ?? 0)}
              decimals={2}
            />
            USD
          </h5>
          <p className="text-slate-500 dark:text-slate-200">Total Revenue</p>
        </div>
      </div>

      <div className="order-3 md:col-span-6 lg:col-span-3 col-span-12 2xl:order-1 bg-sky-100 dark:bg-sky-500/20 card 2xl:col-span-2 group-data-[skin=bordered]:border-sky-500/20 relative overflow-hidden">
        <div className="card-body">
          <ListFilter className="absolute top-0 size-32 stroke-1 text-sky-200/50 dark:text-sky-500/20 ltr:-right-10 rtl:-left-10"></ListFilter>
          <div className="flex items-center justify-center size-12 rounded-md bg-sky-500 text-15 text-sky-50">
            <Coins />
          </div>
          <h5 className="mt-5 mb-2">
            <CountUp
              className="counter-value"
              end={convertToUsd(
                props?.total?.averageOrder?.average_order_value ?? 0
              )}
              decimals={2}
              duration={3}
            />
          </h5>
          USD
          <p className="text-slate-500 dark:text-slate-200">Avg. Order</p>
        </div>
      </div>
      <div className="order-4 md:col-span-6 lg:col-span-3 col-span-12 2xl:order-1 bg-purple-100 dark:bg-purple-500/20 card 2xl:col-span-2 group-data-[skin=bordered]:border-purple-500/20 relative overflow-hidden">
        <div className="card-body">
          <Kanban className="absolute top-0 size-32 stroke-1 text-purple-200/50 dark:text-purple-500/20 ltr:-right-10 rtl:-left-10"></Kanban>
          <div className="flex items-center justify-center size-12 bg-purple-500 rounded-md text-15 text-purple-50">
            <Users />
          </div>
          <h5 className="mt-5 mb-2">
            <CountUp
              end={props?.total?.averageOrder?.order_count ?? 0}
              className="counter-value"
            />
          </h5>
          <p className="text-slate-500 dark:text-slate-200">Total Order</p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Widgets;
