const fx = require("money");

// Set up your base rate data (can come from an API)

export const convertToUsd = (amount: number) => {
  fx.base = "VND";
  fx.rates = {
    USD: 0.000042, // exchange rate from VND to USD
    VND: 1,
  };
  return fx(amount).from("VND").to("USD");
};
