import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import useChartColors from "Common/useChartColors";

type BasicBarChartProps = {
  chartId?: string;
  data?: number[];
  categories?: string[];
};

const BasicBarChart = ({
  chartId,
  data = [],
  categories = [],
}: BasicBarChartProps) => {
  const chartColors = useChartColors(chartId ?? "basicBar");

  const [seriesData, setSeriesData] = useState<any>([
    {
      data: data,
    },
  ]);

  const [options, setOptions] = useState<any>({
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      },
    },
    colors: chartColors,
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: categories,
    },
  });

  // Update series data only when the `data` prop changes
  useEffect(() => {
    setSeriesData([{ data: data }]);
  }, [data]);

  // Update options only when `categories` or `chartColors` change
  useEffect(() => {
    setOptions((prevOptions: any) => ({
      ...prevOptions,
      xaxis: {
        ...prevOptions?.xaxis,
        categories: categories,
      },
      colors: chartColors,
    }));
  }, [categories, chartColors]);

  return (
    <React.Fragment>
      <ReactApexChart
        dir="ltr"
        options={options}
        series={seriesData}
        data-chart-colors='["bg-custom-500"]'
        id={chartId}
        className="apex-charts"
        type="bar"
        height={350}
      />
    </React.Fragment>
  );
};

export { BasicBarChart };
