import React, { useEffect, useMemo, useState } from "react";
import BreadCrumb from "Common/BreadCrumb";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import Flatpickr from "react-flatpickr";

import {
  countShopifyCustomersV1ShopifyShopSystemShopIdSystemCustomerCountGetOptions,
  fetchLessSellingProductsV1ShopifyShopSystemShopIdSystemAnalyticsProductLessSellingPostOptions,
  fetchTopSellingProductsV1ShopifyShopSystemShopIdSystemAnalyticsProductTopSellingPostOptions,
  fetchTotalPriceV1ShopifyShopSystemShopIdSystemAnalyticsOrderTotalRevenuePostOptions,
  getAverageOrderValueV1ShopifyShopSystemShopIdSystemAnalyticsOrderAverageOrderValuePostOptions,
  getCountCustomerByCreatedAtV1ShopifyShopSystemShopIdSystemAnalyticsCustomerCountCustomerByCreatedAtPostOptions,
  getShopifyListV1ShopifyListGetInfiniteOptions,
  getTotalCustomerByCustomerLocaleV1ShopifyShopSystemShopIdSystemAnalyticsOrderTotalOrderByCustomerLocalePostOptions,
  getTotalCustomerMadePurchaseV1ShopifyShopSystemShopIdSystemAnalyticsCustomerPurchasePostOptions,
} from "api/client/@tanstack/react-query.gen";
import Widgets from "./Widgets";
import LocationBased from "./LocationBased";
import Interaction from "./Interaction";
import UserDevice from "./UserDevice";
import Satisfaction from "./Satisfaction";
import DailyVisit from "./DailyVisit";
import ProductsStatistics from "./ProductsStatistics";
import Reports from "./Reports";
import MonthlyCampaign from "./MonthlyCampaign";
import Subscription from "./Subscription";
import TrafficSource from "./TrafficSource";
import TopSellingProducts from "../Ecommerce/TopSellingProducts";
import Select from "react-select";
import { DEFAULT_PAGING } from "Common/constants/api";
import TopCountries from "./TopCountry";

type AnalyticComponentsType = {
  id:
    | "widgets"
    | "locationBased"
    | "interaction"
    | "userDevice"
    | "satisfaction"
    | "dailyVisit"
    | "productsStatistics"
    | "reports"
    | "monthlyCampaign"
    | "subscription"
    | "trafficSource"
    | "topSellingProducts"
    | "lessSellingProducts"
    | "topCountries";
  title: string;
  checked: boolean;
};

// Create a mapping between component ids and their respective components
const COMPONENT_MAP = {
  widgets: Widgets,
  locationBased: LocationBased,
  interaction: Interaction,
  userDevice: UserDevice,
  satisfaction: Satisfaction,
  dailyVisit: DailyVisit,
  productsStatistics: ProductsStatistics,
  reports: Reports,
  monthlyCampaign: MonthlyCampaign,
  subscription: Subscription,
  trafficSource: TrafficSource,
  topSellingProducts: TopSellingProducts,
  lessSellingProducts: TopSellingProducts,
  topCountries: TopCountries,
};

const DEFAULT_LIST_COMPONENTS: AnalyticComponentsType[] = [
  { id: "widgets", title: "Widgets", checked: true },
  { id: "locationBased", title: "Location Based", checked: true },
  { id: "interaction", title: "Interaction", checked: true },
  { id: "userDevice", title: "User Device", checked: false },
  { id: "satisfaction", title: "Satisfaction", checked: false },
  { id: "dailyVisit", title: "Daily Visit", checked: false },
  { id: "productsStatistics", title: "Products Statistics", checked: true },
  { id: "reports", title: "Reports", checked: false },
  { id: "monthlyCampaign", title: "Monthly Campaign", checked: false },
  { id: "subscription", title: "Subscription", checked: false },
  { id: "trafficSource", title: "Traffic Source", checked: false },
  { id: "topSellingProducts", title: "Top Selling Products", checked: true },
  { id: "lessSellingProducts", title: "Less Selling Products", checked: true },
  { id: "topCountries", title: "Top Countries", checked: true },
];

const Analytics = () => {
  let currentDate = new Date();

  const [selectedStore, setSelectedStore] = useState<any>();
  const [dateRange, setDateRange] = useState<Date[]>([
    new Date(currentDate.setMonth(currentDate.getMonth() - 1)),
    new Date(),
  ]);
  const [paging, setPaging] = useState(DEFAULT_PAGING);

  const [listComponent] = useState<AnalyticComponentsType[]>(
    DEFAULT_LIST_COMPONENTS
  );

  const { data: totalRevenue } = useQuery({
    ...fetchTotalPriceV1ShopifyShopSystemShopIdSystemAnalyticsOrderTotalRevenuePostOptions(
      {
        path: { system_shop_id: selectedStore?.value ?? "" },
        body: {
          start_date: dateRange?.[0]?.toISOString(),
          end_date: dateRange?.[1]?.toISOString(),
        },
      }
    ),
    enabled: !!selectedStore?.value && !!dateRange,
  });

  const { data: averages } = useQuery({
    ...getAverageOrderValueV1ShopifyShopSystemShopIdSystemAnalyticsOrderAverageOrderValuePostOptions(
      {
        path: { system_shop_id: selectedStore?.value ?? "" },
        body: {
          start_date: dateRange?.[0]?.toISOString(),
          end_date: dateRange?.[1]?.toISOString(),
        },
      }
    ),
    enabled: !!selectedStore?.value && !!dateRange,
  });

  const { data: customer } = useQuery({
    ...countShopifyCustomersV1ShopifyShopSystemShopIdSystemCustomerCountGetOptions(
      {
        path: { system_shop_id: selectedStore?.value ?? "" },
        body: {
          start_date: dateRange?.[0]?.toISOString(),
          end_date: dateRange?.[1]?.toISOString(),
        },
      }
    ),
    enabled: !!selectedStore?.value && !!dateRange,
  });

  const { data: customersPurchase } = useQuery({
    ...getTotalCustomerMadePurchaseV1ShopifyShopSystemShopIdSystemAnalyticsCustomerPurchasePostOptions(
      {
        path: { system_shop_id: selectedStore?.value ?? "" },
        body: {
          start_date: dateRange?.[0]?.toISOString(),
          end_date: dateRange?.[1]?.toISOString(),
        },
      }
    ),
    enabled: !!selectedStore?.value && !!dateRange,
  });

  const { data: topSellingProducts } = useQuery({
    ...fetchTopSellingProductsV1ShopifyShopSystemShopIdSystemAnalyticsProductTopSellingPostOptions(
      {
        path: { system_shop_id: selectedStore?.value ?? "" },
        body: {
          start_date: dateRange?.[0]?.toISOString(),
          end_date: dateRange?.[1]?.toISOString(),
          limit: 10,
        },
      }
    ),
    enabled: !!selectedStore?.value && !!dateRange,
  });

  const { data: lessSellingProducts } = useQuery({
    ...fetchLessSellingProductsV1ShopifyShopSystemShopIdSystemAnalyticsProductLessSellingPostOptions(
      {
        path: { system_shop_id: selectedStore?.value ?? "" },
        body: {
          start_date: dateRange?.[0]?.toISOString(),
          end_date: dateRange?.[1]?.toISOString(),
          limit: 10,
        },
      }
    ),
    enabled: !!selectedStore?.value && !!dateRange,
  });

  const { data: stores, fetchNextPage } = useInfiniteQuery({
    ...getShopifyListV1ShopifyListGetInfiniteOptions({
      query: {
        limit: paging.limit,
        page: paging.page,
      },
    }),
    getNextPageParam: (lastPage) => {
      if (lastPage?.data && lastPage?.data?.length < paging.limit) {
        return undefined;
      }

      return paging.page + 1;
    },
    initialPageParam: 1,
  });

  const { data: newCustomers } = useQuery({
    ...getCountCustomerByCreatedAtV1ShopifyShopSystemShopIdSystemAnalyticsCustomerCountCustomerByCreatedAtPostOptions(
      {
        path: { system_shop_id: selectedStore?.value ?? "" },
        body: {
          start_date: dateRange?.[0]?.toISOString(),
          end_date: dateRange?.[1]?.toISOString(),
        },
      }
    ),
    enabled: !!selectedStore?.value,
  });

  const { data: topCountries } = useQuery({
    ...getTotalCustomerByCustomerLocaleV1ShopifyShopSystemShopIdSystemAnalyticsOrderTotalOrderByCustomerLocalePostOptions(
      {
        path: { system_shop_id: selectedStore?.value ?? "" },
        body: {
          start_date: dateRange?.[0]?.toISOString(),
          end_date: dateRange?.[1]?.toISOString(),
        },
      }
    ),
  });

  const storeOptions = useMemo(() => {
    return stores?.pages
      ?.map(({ data }) =>
        data?.map((item) => ({
          value: item._id,
          label: item.shop_name,
        }))
      )
      .flat();
  }, [stores]);

  useEffect(() => {
    if (!selectedStore && !!storeOptions && storeOptions.length) {
      setSelectedStore(storeOptions?.[0]);
    }
  }, [selectedStore, storeOptions]);

  const handleLoadMore = () => {
    fetchNextPage();
  };

  return (
    <React.Fragment>
      <BreadCrumb title="Analytics" pageTitle="Dashboards" />
      <div className="p-2 bg-white mb-5 flex gap-2 *:w-1/2 dark:bg-zink-600">
        <Select
          className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800s placeholder:dark:text-white"
          id="choices-single-no-sorting"
          name="choices-single-no-sorting"
          placeholder="Select Store"
          data-choices
          data-choices-sorting-false
          options={storeOptions}
          value={selectedStore}
          onChange={(value: any) => {
            setSelectedStore(value);
          }}
          onMenuScrollToBottom={handleLoadMore}
        />
        <Flatpickr
          value={dateRange}
          options={{ mode: "range", dateFormat: "d M, Y" }}
          placeholder="Select Date"
          onChange={(date) => setDateRange(date)}
          className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
        />
      </div>
      <div className="grid grid-cols-12 gap-x-5">
        {listComponent.map(({ id, checked }) => {
          if (checked) {
            const Component = COMPONENT_MAP[id];
            return id === "widgets" ? (
              <Component
                total={{
                  revenue: totalRevenue?.data?.at(0),
                  averageOrder: averages?.data?.at(0),
                  customer: {
                    total: customer?.data ?? 0,
                    madePurchase: customersPurchase?.data?.length ?? 0,
                  },
                }}
              />
            ) : id === "locationBased" ? (
              <Component
                system_shop_id={selectedStore?.value}
                start_date={dateRange?.[0]?.toISOString()}
                end_date={dateRange?.[1]?.toISOString()}
              />
            ) : id === "interaction" ? (
              <Component data={newCustomers?.data ?? []} />
            ) : id === "topSellingProducts" ? (
              <Component
                name="Top Selling Product"
                products={topSellingProducts?.data ?? []}
              />
            ) : id === "lessSellingProducts" ? (
              <Component
                name="Less Selling Product"
                products={lessSellingProducts?.data ?? []}
              />
            ) : id === "productsStatistics" ? (
              <Component data={(customersPurchase?.data as any) ?? []} />
            ) : id === "topCountries" ? (
              <Component
                system_shop_id={selectedStore?.value}
                start_date={dateRange?.[0]?.toISOString()}
                end_date={dateRange?.[1]?.toISOString()}
              />
            ) : (
              <Component />
            );
          }
          return null;
        })}
      </div>
    </React.Fragment>
  );
};

export default Analytics;
